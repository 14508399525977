var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["anchor-group-wrapper", { "is-expand": _vm.expand }] },
    [
      _c(
        "div",
        {
          class: ["anchor-item-wrapper", { "is-active": _vm.active }],
          on: { click: _vm.locateTitle },
        },
        [
          _c("div", { staticClass: "anchor-circle" }),
          _c(
            "div",
            {
              staticClass: "anchor-title",
              style: { "padding-left": _vm.level * 16 + 36 + "px" },
            },
            [_vm._v(" " + _vm._s(_vm.data.label) + " ")]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "anchor-group-content" },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }