var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "anchor",
      staticClass: "anchor-wrapper beauty-scroll anchor-beauty-scroll",
    },
    [
      _c("div", { staticClass: "anchor-begin" }),
      _c("anchor-tree", {
        ref: "anchorTree",
        attrs: { "title-list": _vm.titleList },
        on: { locateTitle: _vm.handleLocateTitle },
      }),
      _c("div", { staticClass: "anchor-end" }),
      _c(
        "div",
        {
          staticClass: "acitve-anchor-circle",
          style: { top: _vm.activeAnchorTop + "px", left: "13px" },
        },
        [_c("div", { staticClass: "mark" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }